.App {
    min-height: 100%;
    margin: 0;
    padding: 0;
}

.MuiListItem-button {
    > a {
        color: inherit;
        &:hover, &:active, &:focus {
            color: inherit;
            text-decoration: none;
        }
    }
}
.MuiAccordionSummary-content {
    align-items: center;
}

.CondensedTable {
    tr {
        td {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.GlobalLoader {
    text-align: center;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.FileInputName {
    margin: 0;
    margin-top: 5px;
    font-size: 0.8em;
}

.ActionLogs {
    p {
        > span {
            font-weight: bold;
            margin-right: 8px;
        }
    }
}
