.MenuSections {
  a {
    &:hover {
      text-decoration: none;
      color: #333;
    }
    color: #333;
  }
}
.TopMenuSubsection {
  text-decoration: none !important;
  &:hover {
    color: #333 !important;
  }
  color: #333 !important;
}