html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}
