.ErrorBoxContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  h1 {
    margin-top: 0;
  }
  .ErrorBox {
    .ErrorPageCode {
      width: 100%;
      font-weight: bold;
      font-size: 200px;
      line-height: 200px;
    }
    .ErrorPageDescription {
      width: 100%;
      font-size: 20px;
      margin-bottom: 30px;
    }
    .ErrorPageButton {
      width: 100%;
    }
  }
}
