.MenuSections a {
  color: #333; }
  .MenuSections a:hover {
    text-decoration: none;
    color: #333; }

.TopMenuSubsection {
  text-decoration: none !important;
  color: #333 !important; }
  .TopMenuSubsection:hover {
    color: #333 !important; }

.NotificationsMenu {
  margin-left: auto;
  margin-right: 3%;
}

