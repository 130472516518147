.Offline {
  text-align: center;
  height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
  .OfflinePageLogo {
    margin-bottom: 20px;
    img {
      max-height: 140px;
    }
  }
  .OfflinePageDescription {
    font-size: 1.5em;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .OfflinePageApology {
    font-size: 1em;
  }
}
