.AlertsSectionTypes {
  padding: 0;
  h2 {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    text-transform: uppercase;
    font-size: 1.1em;
  }
  .AlertsSectionTypesBody {
    padding: 20px;
    h3 {
      margin-top: 0;
      font-size: 1.1em;
      font-weight: normal;
      text-align: center;
    }
    .AlertsSectionType {
      a {
        text-decoration: none !important;
        cursor: pointer !important;
      }
    }
  }
}
td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}