Button.textTransformNone{
    text-transform: none;
}

Button.active{
    font-weight: bold;
}

Button.inactive{
    color: grey;
}

.relative{
    display: flex;
    position: relative;
}